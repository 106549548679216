import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Homepage from './pages/homepage/home.js';
import AboutPage from './pages/about/about.js';
import NotFoundPage from './pages/notFoundPage/nfp.js';
import LogIn from './pages/login/login.js';
import ProjectSearch from './pages/search/search.js';

import InProgressProjects from './pages/projects/inProgressProjects.js';

import NewProject from './pages/projects/newProject.js';


function App() {
  const isAuthenticated = Boolean(localStorage.getItem('token'));
  const lastActivity = useRef(localStorage.getItem('lastActivity') || Date.now());


  // localStorage.removeItem('token'); use for logout later

  useEffect(() => {
    const setActivity = () => {
      lastActivity.current = Date.now();
    };
    window.addEventListener('mousemove', setActivity);
    window.addEventListener('click', setActivity);
    window.addEventListener('keydown', setActivity);
    window.addEventListener('scroll', setActivity);
    window.addEventListener('beforeunload', setActivity);
    return () => {
      window.removeEventListener('mousemove', setActivity);
      window.removeEventListener('click', setActivity);
      window.removeEventListener('keydown', setActivity);
      window.removeEventListener('scroll', setActivity);
      window.removeEventListener('beforeunload', setActivity);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('lastActivity', lastActivity.current);
    const timeout = setTimeout(() => {
      if (Date.now() - lastActivity.current > 30 * 60 * 1000) {
        // 30 minutes of inactivity -> remove token
        localStorage.removeItem('token');
      }
    },  30 * 60  * 1000);
    return () => clearTimeout(timeout);
  }, []);


  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LogIn />} />
        <Route path="about" element={isAuthenticated ? <AboutPage /> : <Navigate to="/login" />} />
        <Route path="/search" element={isAuthenticated ? <ProjectSearch /> : <Navigate to="/login" />} />
        <Route path="/search/:keyword" element={isAuthenticated ? <ProjectSearch /> : <Navigate to="/login" />} />
        <Route path="/" element={isAuthenticated ? <Homepage /> : <Navigate to="/login" />} />

        <Route path="/yourProjects" element={isAuthenticated ? <InProgressProjects /> : <Navigate to="/login" />} />

        <Route path="/projects/new" element={isAuthenticated ? <NewProject /> : <Navigate to="/login" />} />



        <Route path="/*" element={<NotFoundPage />} /> {/* Catch-all route */}
      </Routes>
    </Router>
  );
}

export default App;
