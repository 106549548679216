import React, { useEffect, useRef } from 'react';
import './sidebar.css';
import { ReactComponent as SVG } from './x-symbol-svgrepo-com.svg';

function Sidebar({ isOpen, onClose }) {
    const sidebarClass = isOpen ? 'sidebar open' : 'sidebar';
    const sidebarRef = useRef();
    const staffName = localStorage.getItem('staffName');
    const handleSignOut = () => {
        localStorage.removeItem('token'); 
        window.location.href = '/login'; 
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                console.log('Clicked outside the sidebar');
                console.log('Current token:', localStorage.getItem('token'));
                console.log('Current staffname:', localStorage.getItem('staffName'));
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) {
        setTimeout(() => {
        return null;
        }, 300)
    }

    return (
        <div className={isOpen ? 'sidebar open' : 'sidebar'} ref={sidebarRef}>
            <div className={sidebarClass}>
                <div className='sidebar-top'>
                <button onClick={onClose}>
                    <SVG />
                </button>
                </div>
                <div className='sidebar-USER'>
                {staffName}
                </div>
                <div className='sidebar-middle'>
                <a href="/settings">Settings</a>
                <a href="/account">Account</a>
                </div>
                <div className='sidebar-bottom'>
                <button onClick={handleSignOut}>Sign Out</button>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;