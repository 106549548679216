import React from 'react';
import './home.css';
import Header from '../../misc/header.js';
import axios from 'axios';
import SearchForm from '../../misc/searchform';

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      leaveApplications: [],
      keyword: '',
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const promises = [];
    const currentYear = new Date().getFullYear();

    for (let i = 0; i < 10; i++) {
      const year = currentYear - i;
      promises.push(axios.get(`http://143.198.132.184:3000/api/homepage/overview?year=${year}`));
    }

    promises.push(axios.get('http://143.198.132.184:3000/leave-applications'));

    promises.push(axios.get('http://143.198.132.184:3000/api/holidays'));

    Promise.all(promises)
      .then((responses) => {
        const projectData = responses.slice(0, 10).map((response, index) => ({
          year: currentYear - index,
          ...response.data,
        }));
        const leaveApplications = responses[responses.length - 1].data;
        const holidays = responses[responses.length - 1].data;
        this.setState({
          data: projectData,
          leaveApplications,
          holidays,
          loading: false
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loading: false });
      });
  }

  handleChange(event) {
    this.setState({ keyword: event.target.value });
  }
  
  async handleSubmit(event) {
    event.preventDefault();
    window.location.href = `/search?keyword=${encodeURIComponent(this.state.keyword)}`;
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Header />
          <div className='Loading'>
            <h1>Loading...</h1>
          </div>
        </div>
      );
    }
    return (
      <div className="HomepageHeader">
        <Header />
        <div className="Homepage">
        <h1>Projects Overview</h1>
        <table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Total Projects</th>
                <th>In Progress</th>
                <th>Completed</th>
                <th>Closed</th>
                <th>Stopped</th>
                <th>Cancelled</th>
                <th>On Hold</th>
                <th>Standing Offer</th>
                <th>KPI of Projects</th>
                <th>KPI (P)</th>
                <th>F. Gov. Projects</th>
                <th>KPI of Contract Value</th>
                <th>KPI (A)</th>
              </tr>
            </thead>
            <tbody>
            {this.state.data.map((row) => {
              return (
                <tr key={row.year}>
                  <td>{row.year}</td> 
                  <td>{row.Project_count}</td>
                  <td>{row.Project_progress_count}</td>
                  <td>{row.Project_completed_count}</td>
                  <td>{row.Project_closed_count}</td>
                  <td>{row.Project_stop_count}</td>
                  <td>{row.Project_cancelled_count}</td>
                  <td>{row.Project_onhold_count}</td>
                  <td>{row.Project_information_count}</td>
                  <td>{Math.round(parseFloat(row.Rate_KPI) * 100) / 100}%</td>
                  <td>{row.KPI.toLocaleString()}</td>
                  <td>{row.Gov_Project_count}</td>
                  <td>{Math.round(parseFloat(row.Rate_KPI_P) * 100) / 100}%</td>
                  <td>{row.KPI_P.toLocaleString()}</td>
                </tr>
              ); 
            })}
            </tbody>
          </table>
        </div> 
        <SearchForm />
        <div className="horizontalBar"></div>
        {this.state.leaveApplications.some(leave => leave.Staff_Name && leave.Start_date && leave.Ending_date && leave.Starting_time && leave.Ending_time && leave.Total_hours && leave.Replacement_Colleague && leave.Approved_results) ? (
          <div className='leaveTable'>
            <h1>Leave Applications</h1>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Total Hours</th>
                    <th>Temporary Responsibility</th>
                    <th>Result</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.leaveApplications.map((leave) => (
                    <tr key={leave.id}>
                      <td>{leave.Staff_Name}</td>
                      <td>{leave.Start_date}</td>
                      <td>{leave.Ending_date}</td>
                      <td>{leave.Starting_time}</td>
                      <td>{leave.Ending_time}</td>
                      <td>{leave.Total_hours}</td>
                      <td>{leave.Replacement_Colleague}</td>
                      <td>{leave.Approved_results}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="horizontalBar"></div>
            </div>
        ) : null}
        {this.state.holidays.length > 0 ? (
          <div className='holidayTable'>
              <h1>Upcoming Holidays</h1>
              <table>
                  <thead>
                      <tr>
                          <th>Date</th>
                          <th>Weekday</th>
                          <th>Name of Holiday</th>
                      </tr>
                  </thead>
                  <tbody>
                  {this.state.holidays.map((holiday, index) => (
                    holiday.date_all && holiday.H_Weekday && holiday.Holiday_name ? (
                      <tr key={index}>
                        <td>{holiday.date_all}</td>
                        <td>{holiday.H_Weekday}</td>
                        <td>{holiday.Holiday_name}</td>
                      </tr>
                    ) : null
                  ))}
                  </tbody>
              </table>
              <div className="horizontalBar"></div>
          </div>
        ) : null}
        <div className='userDetails'>
        <h1>Hello {localStorage.getItem('staffName').split(' ')[0]}!</h1>
          <div className='buttonContainer'>
            <a href={`/yourProjects?staffName=${localStorage.getItem('staffName')}`}>Your Projects</a>
            <a href='/allProjects'>All Projects</a>
            <a href='/yourProposals'>Your Progress</a>
            <a href='/allProposals'>All Proposals</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;
