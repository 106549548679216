import React, { useState, useEffect } from 'react';
import HeaderLogin from '../../misc/headerLogin'
import './login.css';
import CountUp from 'react-countup';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [staffName, setStaffName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        const name = localStorage.getItem('staffName'); 
        setStaffName(name); 
    }, [staffName]); 

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('Form submitted with username:', username, 'and password:', password);
        try {
            const response = await fetch('http://143.198.132.184:3000/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });
            
            console.log('Response received:', response);
            console.log('localStorage:', localStorage); 
            
            if (!response.ok) {
                console.log('Error status:', response.status);
                setErrorMessage(null);
                setTimeout(() => setErrorMessage('Invalid username or password'), 50);
                setPassword('');
                return;
            }
            
            const data = await response.json();
            console.log('Response data:', data);
            console.log(data.staffName);
    
            if (data.token) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('staffName', data.staffName);
                window.location.href = '/'
            } else {
                console.log('Invalid username or password')
                setErrorMessage('Invalid username or password')
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setErrorMessage('Invalid username or password')
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('http://143.198.132.184:3000/api/companyInfo');
            const data = await response.json();

            setData(data);
        };

        fetchData();
    }, []);


    if (!data) {
        return null; 
    }

    return (
        <div className='Login'>
            <HeaderLogin />
            <form onSubmit={handleSubmit}>
                <label className='form-label'>
                    Username: 
                    <input type="text" className="form-input" value={username} onChange={e => setUsername(e.target.value)} />
                </label>
                <label className='form-label'>
                    Password: 
                    <input type="password" className="form-input" value={password} onChange={e => setPassword(e.target.value)} />
                </label>
                {errorMessage && <p className='error'>{errorMessage}</p>}
                <input type="submit" className="form-submit" value="Log In" />
            </form>
            {data && 
                <div className='company-info'>
                <p>
                    ZiyuTec Inc. has helped <br />
                    <span className='company-info-color'> <CountUp start={0} end={data.customerCount} duration={2.75} /></span> clients on <br />
                    <span className='company-info-color'> <CountUp start={0} end={data.totalProjectNumber} duration={2.75} /></span> projects, totalling <br />
                    <span className='company-info-color'> <CountUp start={0} end={data.totalProjectArea} duration={2.75} /></span> square feet.
                </p>
                    
                </div>
                }
        </div>
    );
}

export default Login;