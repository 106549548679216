import React from 'react';
import './header.css'; 
import image from '../ziyutec logo long.png'; 
import Sidebar from './sidebar.js';

class Header extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = { showDropdown: false };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = { isSidebarOpen: false }; 
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  toggleDropdown() {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  }

  toggleSidebar() {
    this.setState(prevState => ({ isSidebarOpen: !prevState.isSidebarOpen }));
  }

  
  render() {
    const staffName = localStorage.getItem('staffName');
    return (
        <header className="Header">
            <div className="logo">
                <a href="/">
                    <img src={image} alt="Company Logo" /> {}
                </a>
            </div>
            <nav className="navigation">
                <div className="dropdown">
                    <span className="dropbtn">PROJECTS</span>
                    <div className="dropdown-content">
                        <a href="/projects/new">New Project</a>
                        <a href="/projects/completed">Completed</a>
                        <a href="/projects/upcoming">Upcoming</a>
                    </div>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">PROPOSALS</span>
                    <div className="dropdown-content">
                        <a href="/proposals/ongoing">Ongoing</a>
                        <a href="/proposals/completed">Completed</a>
                        <a href="/proposals/upcoming">Upcoming</a>
                    </div>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">CLIENTS</span>
                    <div className="dropdown-content">
                        <a href="/proposals/ongoing">Ongoing</a>
                        <a href="/proposals/completed">Completed</a>
                        <a href="/proposals/upcoming">Upcoming</a>
                    </div>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">VENDORS</span>
                    <div className="dropdown-content">
                        <a href="/proposals/ongoing">Ongoing</a>
                        <a href="/proposals/completed">Completed</a>
                        <a href="/proposals/upcoming">Upcoming</a>
                    </div>
                </div>
                <div className="dropdown">
                    <span className="dropbtn">HR</span>
                    <div className="dropdown-content">
                        <a href="/proposals/ongoing">Ongoing</a>
                        <a href="/proposals/completed">Completed</a>
                        <a href="/proposals/upcoming">Upcoming</a>
                    </div>
                </div>
            </nav>
            <nav className='profile'> 
              <span className="dropbtnU" onClick={this.toggleSidebar}>{staffName}</span>
            </nav>
            <Sidebar isOpen={this.state.isSidebarOpen} onClose={this.toggleSidebar} staffName={this.props.staffName} />
        </header>
    );
}
}

export default Header;