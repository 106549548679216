import React, { useState } from 'react';
import './newProject.css';
import Header from '../../misc/header.js';

function NewProject() {
  const [projectDetails, setProjectDetails] = useState({
    projectNumber: '',
    projectName: '',
    // Add more fields as necessary
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', projectDetails);

  };

  return (
    <div className="NewProject">
        <Header />
        <form onSubmit={handleSubmit} className="new-project-form">
        <div className="form-group">
            <label htmlFor="projectNumber">Project Number</label>
            <input
            type="text"
            id="projectNumber"
            name="projectNumber"
            value={projectDetails.projectNumber}
            onChange={handleChange}
            />
        </div>
        {/* More input fields similar to the above */}
        <button type="submit">Create New Project</button>
        </form>
    </div>
  );
}

export default NewProject;