import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from '../../misc/header';
import './search.css';
import SearchForm from '../../misc/searchform';

function Search() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const keyword = searchParams.get('keyword');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      setLoading(true);
      const fetchData = async () => {
          try {
              const response = await axios.post('http://143.198.132.184:3000/api/search', { KeyWordSearch: keyword });
              setResults(response.data);
          } catch (error) {
              console.error('An error occurred:', error);
          } finally {
              setLoading(false);
          }
      };

      fetchData();
  }, [keyword]);

    return (
        <>
            <Header />
            <SearchForm />
            {loading ? (
                <div className='Loading'>Loading...</div>
            ) : (
                <div>
                    <h1 className="searchResultsTitle">PROJECT SEARCH RESULTS FOR "{keyword}"</h1>
                    {Array.isArray(results) && (
                        <table className="myTable">
                            <thead>
                                <tr>
                                <td className="boldText" width='7.5%' bgcolor='#86C4E7'>Project Number</td>
                                <td className="boldText" width='7.5%' bgcolor='#E0EFF8'>Proposal Number</td>
                                <td className="boldText" width='40%' bgcolor='#86C4E7'>Project Name</td>
                                <td className="boldText" width='10%' align='center' bgcolor='#E0EFF8'>Discipline</td>
                                <td className="boldText" width='10%' align='center' bgcolor='#86C4E7'>Discipline Lead</td>
                                <td className="boldText" width='5%' bgcolor='#E0EFF8'>Contract Value</td>
                                <td className="boldText" width='5%' bgcolor='#86C4E7'>Budget Spent</td>
                                <td className="boldText" width='5%' bgcolor='#E0EFF8'>Remaining Budget</td>
                                <td className="boldText" width='10%' bgcolor='#86C4E7'>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {results.map(result => (
                                    <tr key={result.id}> 
                                        <td>{result.Project_Number}</td>
                                        <td>{result.Proposal_Number}</td>
                                        <td>{result.Project_Name}</td>
                                        <td>{result.Discipline}</td>
                                        <td>{result.DisciplineLead}</td>
                                        <td className="alignRight" >{result.ContractValue}</td>
                                        <td className="alignRight" >{result.ProjectSpent}</td>
                                        <td className="alignRight" style={{
                                            backgroundColor: parseFloat(result.totalBudgetRemaining) > 0 ? 'rgb(185, 250, 190)' :
                                                            parseFloat(result.totalBudgetRemaining) < 0 ? 'rgb(250, 180, 180)' :
                                                            parseFloat(result.totalBudgetRemaining) === 0 ? 'rgb(255, 245, 175)' : 'rgb(255, 255, 255)'
                                        }}>
                                            {result.totalBudgetRemaining}
                                        </td>
                                        <td>{result._Status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            )}
        </>
    );
}

export default Search;
