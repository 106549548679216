import React from 'react';
import './about.css';
import Header from '../../misc/header';

class About extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      aboutData: null,
    };
  }

  componentDidMount() {
    fetch('http://143.198.132.184:3000/about')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => this.setState({ aboutData: data }))
      .catch(error => console.error('Error:', error));
  }
  render() {
    const { aboutData } = this.state;

    if (!aboutData) {
      return <div>Loading...</div>;
    }

    return (
      <div className="About">
        <Header />
        <h1>{aboutData.message}</h1>
      </div>
    );
  }
}

export default About;